<template>
  <div class="choose-box">
    <div class="title">{{ $t('carService.chooseUs.title') }}</div>
    <phone />
    <div class="scroll-box">
      <div class="btn" @click="handlePlay">
        <span v-if="isPlay" class="iconfont icon-zanting"></span>
        <span v-else class="iconfont icon-bofang"></span>
      </div>
      <div @mouseover="handleMouseover" @mouseout="handleMouseout">
        <vue-seamless-scroll
          :key="key"
          :data="listData"
          :class-option="classOption"
          class="warp"
          ref="scroll"
        >
          <ul class="ul-item">
            <li class="li-item" v-for="(item, index) in listData" :key="index">
              <img :src="item.src" />
              <div class="desc">{{ $t('carService.chooseUs.text' + (index + 1)) }}</div>
            </li>
          </ul>
        </vue-seamless-scroll>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll'
import Phone from '@/components/Common/Phone/phone.vue'

export default {
  name: 'ChooseUs',
  components: {
    Phone,
    vueSeamlessScroll
  },
  data() {
    return {
      listData: [
        {
          src: process.env.VUE_APP_FILE_URL + 'carServe/advantage/1.png'
        },
        {
          src: process.env.VUE_APP_FILE_URL + 'carServe/advantage/2.png'
        },
        {
          src: process.env.VUE_APP_FILE_URL + 'carServe/advantage/3.png'
        },
        {
          src: process.env.VUE_APP_FILE_URL + 'carServe/advantage/4.png'
        },
        {
          src: process.env.VUE_APP_FILE_URL + 'carServe/advantage/5.png'
        }
      ],
      classOption: {
        limitMoveNum: 2,
        direction: 2,
        step: 1,
        hoverStop: false
      },
      key: 0,
      isPlay: true
    }
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    handleResize() {
      this.key = Math.random()
    },
    handleMouseover() {
      this.classOption.step = 0.5
    },
    handleMouseout() {
      this.classOption.step = 1
    },
    handlePlay() {
      if (this.isPlay) {
        this.$refs.scroll._cancle()
      } else {
        this.$refs.scroll._startMove()
      }
      this.isPlay = !this.isPlay
    }
  }
}
</script>

<style lang="scss" scoped>
.choose-box {
  background-color: $bg-color;
  padding: 142px 0 162px;
  .title {
    text-align: center;
    color: $light-color;
    font-weight: bold;
    font-size: 80px;
    line-height: 117px;
    margin-bottom: 47px;
  }
  .scroll-box {
    margin-top: 26px;
    .btn {
      margin-bottom: 40px;
      text-align: right;
      padding: 0 40px;
      .iconfont {
        color: $light-color;
        font-size: 36px;
        cursor: pointer;
      }
    }
    .warp {
      width: 480px * 4;
      //height: 260px;
      margin: 0 auto;
      //overflow: hidden;
      ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;
        &.ul-item {
          display: flex;
          .li-item {
            width: 460px;
            //height: 260px;
            margin-left: 20px;
            color: #fff;
            //text-align: center;
            font-size: 30px;
            img {
              width: 460px;
              height: 260px;
            }
            .desc {
              color: #ffffff;
              font-weight: bold;
              font-size: 18px;
              line-height: 18.8px;
              letter-spacing: -0.2px;
              margin: 20px 0 0;
            }
          }
        }
      }
    }
  }
}
</style>
