<template>
  <div>
    <centered-auto-swiper />
    <car-service id="service" />
    <choose-us id="choose" />
  </div>
</template>

<script>
import ChooseUs from '@/components/Moudle/CarService/ChooseUs.vue'
import CenteredAutoSwiper from '@/components/Moudle/CarService/CenteredAutoSwiper.vue'
import CarService from '@/components/Moudle/CarService/CarService.vue'

export default {
  components: { CarService, CenteredAutoSwiper, ChooseUs },
  data() {
    return {}
  },
  mounted() {
    if (this.$route.query.type == 2) {
      this.$nextTick(() => {
        let top = document.getElementById('service').offsetTop
        window.scrollTo({ top: top, behavior: 'smooth' })
      })
    } else if (this.$route.query.type == 3) {
      this.$nextTick(() => {
        let top = document.getElementById('choose').offsetTop
        window.scrollTo({ top: top, behavior: 'smooth' })
      })
    }
  }
}
</script>

<style scoped lang="scss"></style>
