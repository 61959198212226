<template>
  <div class="service-box flex">
    <div
      class="item"
      :class="hoverIndex === 0 ? 'act' : ''"
      @mouseover="mouseOver(0)"
      @mouseout="hoverIndex = ''"
    >
      <img :src="imgList[0]" />
      <div class="bg flex flex-col col-center row-between">
        <div class="title">{{ $t('carService.img1.label') }}</div>
        <div class="content">
          {{ $t('carService.img1.desc') }}
        </div>
      </div>
    </div>
    <div
      class="item"
      :class="hoverIndex === 1 ? 'act' : ''"
      @mouseover="mouseOver(1)"
      @mouseout="hoverIndex = ''"
    >
      <img :src="imgList[1]" />
      <div class="bg flex flex-col col-center row-between">
        <div class="title">{{ $t('carService.img2.label') }}</div>
        <div class="content">
          {{ $t('carService.img2.desc') }}
        </div>
      </div>
    </div>
    <div
      class="item"
      :class="hoverIndex === 2 ? 'act' : ''"
      @mouseover="mouseOver(2)"
      @mouseout="hoverIndex = ''"
    >
      <img :src="imgList[2]" />
      <div class="bg flex flex-col col-center row-between">
        <div class="title">{{ $t('carService.img3.label') }}</div>
        <div class="content">
          {{ $t('carService.img3.desc') }}
        </div>
      </div>
    </div>
    <div
      class="item"
      :class="hoverIndex === 3 ? 'act' : ''"
      @mouseover="mouseOver(3)"
      @mouseout="hoverIndex = ''"
    >
      <img :src="imgList[3]" />
      <div class="bg flex flex-col col-center row-between">
        <div class="title">{{ $t('carService.img4.label') }}</div>
        <div class="content">
          {{ $t('carService.img4.desc') }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CarService',
  data() {
    return {
      hoverIndex: '',
      imgList: [
        process.env.VUE_APP_FILE_URL + 'carServe/config/1.png',
        process.env.VUE_APP_FILE_URL + 'carServe/config/2.png',
        process.env.VUE_APP_FILE_URL + 'carServe/config/3.png',
        process.env.VUE_APP_FILE_URL + 'carServe/config/4.png'
      ]
    }
  },
  methods: {
    mouseOver(index) {
      this.hoverIndex = index
    }
  }
}
</script>

<style scoped lang="scss">
.service-box {
  background-color: $bg-color;
  padding: 0 150px;
  gap: 60px;
  color: #ffffff;
  .act {
    .bg {
      background: none !important;
    }
    img {
      transition: all 0.6s ease 0s;
      transform: scale(1.05);
    }
  }
  .item {
    position: relative;
    cursor: pointer;
    width: 360px;
    height: 868px;
    &:first-child,
    &:nth-child(3) {
      margin: 172px 0 40px;
    }
    &:nth-child(2),
    &:last-child {
      margin: 102px 0 110px;
    }
    img,
    .bg {
      width: 360px;
      height: 868px;
      border-radius: 18px;
      background: rgba(0, 0, 0, 0.35);
      //&:hover {
      //  background: none !important;
      //}
    }
    img {
      &:hover {
        transform: scale(1.1);
      }
    }
    .bg {
      padding: 120px 30px 160px;
      box-sizing: border-box;
      position: absolute;
      top: 0;
      .title {
        font-size: 32px;
        line-height: 47px;
        letter-spacing: -0.2px;
        text-align: center;
      }
      .content {
        font-size: 20px;
        line-height: 25px;
        letter-spacing: 0.2px;
        text-align: center;
      }
    }
  }
}
</style>
